import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledThankYou = styled.section`
  padding: 60px 0;
  text-align: center;
  margin-bottom: 150px;

  ${breakpoint.medium`
    padding: 90px 0;
  `}

	h2 {
		margin: 0 auto 2rem;
	}

  a {
    color: ${colors.orange};

    &:hover,
    &:active {
      color: ${colors.purpleDark};
    }
  }
`

const ThankYou = props => {
  return (
    <StyledThankYou>
      <Container data-aos="fade-up">
        {(props.first_name) ?
          <p>Thank you for your interest, <b>{props.first_name}</b>. We will be in touch!</p>
          :
          <p>Thank you for your interest. We will be in touch!</p>
        }
      </Container>
    </StyledThankYou>
  )
}
export default ThankYou